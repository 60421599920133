export const USER_SET_PROFILE = 'USER_SET_PROFILE';
export const GET_USER_PROFILE ='GET_USER_PROFILE';


/* UPDATE USER PROFILE */
export function setuserProfile(payload){
    return {
        type:'USER_SET_PROFILE',
        payload
    }

}

export function getUserProfile(){
    return{
        type:'GET_USER_PROFILE'
       
    }
}
