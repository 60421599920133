
export const SHOW_LOGIN = 'SHOW_LOGIN';
export const LOGIN_POPUP = 'LOGIN_POPUP' ;

/*GET_ALL_PRODUCT*/
export function showLogin(payload){
    return{
        type:'SHOW_LOGIN',
        payload
    }
}

export function getLoginPopupStatus(){
    return{
        type:'LOGIN_POPUP'
    }
}


