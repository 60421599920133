import React,{ Component } from 'react';
import {useState, useEffect} from 'react';
import styles from './Topnavigation.module.css';
import Login from '../login/index';
import { Button, notification } from 'antd';
import {Route, Link, BrowserRouter} from 'react-router-dom';
import Request from '../../utils/request';


const Topnavigation = () => {
   //[change, setChange] = useState(true);  
     const [navigation,setnavigation] = React.useState([]);  
   const [loading,setLoading] = React.useState(false);

   React.useEffect(() => {
    async function fetchMyAPI() {
    
     
        setLoading(true);
        try {
          
          const value={locationId:1};
          const res = await Request.axios('post', 'getAllLocation',value);
          if(res && res.success) {
           // console.log(res.data);
            setnavigation(res.data);
          } else {
            notification.error({
              message: res.message,
            });
          }
         
        } catch (error) {
          notification.error({
            message: 'Failed to get data.',
          });
        }
        setLoading(false);
      
    }
    fetchMyAPI()
  }, []);
   

  const setLocation = (location)=>{
     location.data.id= location.id;
     let locationInfo = location.data;
     delete locationInfo.action;
     delete locationInfo.edit;
     localStorage.setItem('locationInfo', JSON.stringify(locationInfo));

  }
  let menuList = navigation.length > 0
  && navigation.map((item, i) => {
  return (
    <li key={i} ><Link to={`/location/${item.id}`} className="active" onClick={()=>setLocation(item)}>{item.data.LocationName}</Link></li>
  )
}, this);

const [toggleMenu, setToggleMenu] = useState(false)
const [screenWidth, setScreenWidth] = useState(window.innerWidth)


const toggleNav = () => {
  setToggleMenu(!toggleMenu)
}

useEffect(() => {

  const changeWidth = () => {
    setScreenWidth(window.innerWidth);
  }

  window.addEventListener('resize', changeWidth)

  return () => {
      window.removeEventListener('resize', changeWidth)
  }

}, [])

  return(
  <div className={styles.Topnavigation} data-testid="Topnavigation">
  <div onClick={toggleNav} className="nav-button-wrap">
                          <div className="nav-button">
                              <span></span><span></span><span></span>
                          </div>
                      </div>
      
                      <div className="nav-holder">
    <nav>
    {(toggleMenu || screenWidth > 500) && (
                              <ul className="list">
                                  <li  className="items"><Link to="/" className="">Home</Link></li>
                                  <li  className="items"><Link to="/about" className="">About</Link></li>
                                  <li  className="items"><Link to="/gallery" className="">Media</Link></li>
                                  
                                 {/**  <li className="nav-bg-li items">
                                      <a href="#">Order Now<i className="fas fa-caret-down"></i></a>                                 
                                      <ul>
                                          {menuList}
                                      </ul>
                                      
                                  </li> */}
                                  <li  className="items"><Link to="/menu" className="">Menu</Link></li>
                                  <li className="items"><Link to="/eventbooking" className="">Event Booking</Link></li>
                                  <li  className="items"><Link to="/contact" className="">Contact</Link></li>                                
                                 {/* <li className="items"><Login/> </li>*/}
                                
                              </ul>
                                )}
                                  

                      
                          </nav>
                          </div>
  </div>
  );
}

//Topnavigation.propTypes = {};

//Topnavigation.defaultProps = {};

export default Topnavigation;
