import React from 'react';
import {Link} from 'react-router-dom';
import Topnavigation from '../Topnavigation/Topnavigation';
import {ShoppingCartOutlined } from '@ant-design/icons';
import { Badge, Avatar } from 'antd';
import  {connect} from  'react-redux'


class Header extends React.Component {
    constructor(props){
        super(props);
         this.state = {
            activeClass:'',
        }
    }
    componentDidMount() {
        // this is needed, because InfiniteCalendar forces window scroll
        //window.scrollTo(0, 0);
        window.addEventListener('scroll', () => {
          
            if(window.scrollY > 100){
              
                this.setState({ activeClass:'scroll-sticky' });
            }
            if(window.scrollY === 0){
              
                this.setState({ activeClass:'' });
            }
           
         });
      }
 
  render() {
    return(<header className={`main-header ${this.state.activeClass}`}>
      
          <div className="header-top">
              <div className="container">
              <div className="top-adress-bar">     
              <div className="lang-wrap header-top_contacts"></div>
                  <div className="header-top_contacts"><a href="tel:+91 77382 17738"><span>Khalapur:</span>  +91 77382 17738</a><a href="mailto:zaitoontr@gmail.com"><span>Write :</span>zaitoontr@gmail.com</a></div>
              </div>
              </div>
          </div>
          <div className="header-inner  fl-wrap">
              <div className="container">
                  <div className="header-container fl-wrap">
                  <Link to="/" className="logo-holder ajax"><img src="/assets/images/logo.png" alt=""/></Link>
                     
                       
                      {/* <div className="show-reserv_button show-rb"><span>Order Now</span> <i className="fal fa-bookmark"></i></div> */}
                      {/* <div className="show-share-btn showshare htact"><i className="fal fa-bullhorn"></i> <span className="header-tooltip">Share</span></div> */}
                      <div className="show-share-btn showshare htact"><Link to="/cart"><Badge count={this.props.numberCart} offset={[4, -5]}><ShoppingCartOutlined  style={{ fontSize: '22px', color: '#c19d60' }}/> </Badge></Link></div>
            
                   
                          <Topnavigation />
                         
                     
                       
                      {/* <div className="share-wrapper isShare">
                          <div className="share-container fl-wrap"></div>
                      </div> */}
                     
              
              </div>
          </div>
         
          </div>
          </header>
    );
  }
}
const mapStateToProps = state =>{
    return{
        numberCart:state._cartReducer.numberCart
 
    }
}
export default connect(mapStateToProps,null)(Header)
