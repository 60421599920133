import React from "react";

class MenuPage extends React.Component{
    constructor(props) {
        super(props);
       
      }
      
  
  
  render() {



    return (
<>

                <div classNameName="content-fluid">
               
                      <section className="parallax-section hero-section_2 hidden-section" data-scrollax-parent="true">
                        <div className="bg par-elem" style={{ backgroundImage: `url(${'./assets/images/bg/menu_banner.jpg'})` }}data-scrollax="properties: { translateY: '30%' }"></div> 
                        
                    
                 
                        <div className="brush-dec"></div>
                    </section> 
                 
                    <section className="hidden-section no-padding menu_card " data-scrollax-parent="true">
                    <div className="brush-dec2 brush-dec_top"></div>
                    <img src="/assets/images/all/menu_page-0001.jpg"   alt="ok"/>
                    <img src="/assets/images/all/menu_page-0002.jpg"   alt="ok"/>
                    <img src="/assets/images/all/menu_page-0003.jpg"   alt="ok"/>
                    <img src="/assets/images/all/menu_page-0004.jpg"   alt="ok"/>
                    <img src="/assets/images/all/menu_page-0005.jpg"   alt="ok"/>
                    <img src="/assets/images/all/menu_page-0006.jpg"   alt="ok"/>
                    <img src="/assets/images/all/menu_page-0007.jpg"   alt="ok"/>
                    <img src="/assets/images/all/menu_page-0008.jpg"   alt="ok"/>
                    <img src="/assets/images/all/menu_page-0009.jpg"   alt="ok"/>
                    <img src="/assets/images/all/menu_page-0010.jpg"   alt="ok"/>
                   {/** <iframe src="./assets/images/menu/menu.pdf"    style={{ frameborder: "0", scrolling: "no" ,display:"block",width:"100%",height:"100vh"}}> </iframe>
                    <iframe src="https://zaitoon.virtualstacks-demosite.com/pdfviewer/index.html"    style={{ frameborder: "0", scrolling: "no" ,display:"block",width:"100%",height:"100vh"}}> </iframe>*/}
                  
           
                    
                 
                      
                    </section>
                  
              
                </div>
              
</>


    );


}
}
export default MenuPage;
