import {USER_SET_PROFILE,GET_USER_PROFILE} from '../actions/userAction';
import {getUserLS} from '../../utils/user';

var initialState ="";
const storeProfile  = loadFromLocalStorage('userProfile');
if(storeProfile!="" && storeProfile!=undefined){
    initialState = storeProfile;
}else{
    initialState = {   
      profile:{firstName: '',
        lastName: '',
        mobile: '',
        email: ''}
        
      };
}


function userReducer(state=initialState,action){
    switch(action.type){
        case USER_SET_PROFILE:     
           
           state={
            profile:action.payload  
           }
           /* let data= {
                ...state,
                         
              }*/
              saveToLocalStorage(state); 
              return state;
        case GET_USER_PROFILE:  
            return{
                ...state
            }

        default:
            return state
    }

}

// convert object to string and store in localStorage
function saveToLocalStorage(state) {
    try {
      const serialisedState = JSON.stringify(state);
      localStorage.setItem("userProfile", serialisedState);
    } catch (e) {
      console.warn(e);
    }
  }
  
  // load string from localStarage and convert back in to an Object
  // invalid output must be undefined
  function loadFromLocalStorage(Info) {
    try {
      const serialisedState = localStorage.getItem(Info);
      if (serialisedState === null) return undefined;
      return JSON.parse(serialisedState);
    } catch (e) {
      console.warn(e);
      return undefined;
    }
  }



export default userReducer;