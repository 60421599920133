import { combineReducers } from 'redux';
import cartReducer  from './cartReducer';
import userReducer from './userReducer';
import loginReducer from './loginReducer';
import loaderReducer from './loaderReducer';

const rooReducer =  combineReducers({
    _cartReducer: cartReducer,
    _userReducer:userReducer,
    _loginReducer:loginReducer,
    _loaderReducer:loaderReducer
});

export default rooReducer;
