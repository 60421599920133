import React from 'react';
import {Route, Link, BrowserRouter} from 'react-router-dom';

import { Navbar, Nav, NavItem } from 'reactstrap';
import  {connect} from  'react-redux'


//import SourceLink from 'components/SourceLink';

class Footer extends React.Component {
    constructor(props){
        super(props);
         this.state = {
            activeClass:'',
        }
    }

render() {
  return (
    <>
    {this.props.status && (
    <div id="load_div">
        <div className="lds-ellipsis"></div></div>
    )}
    <div className="height-emulator fl-wrap"></div>
    <footer className="fl-wrap dark-bg fixed-footer">
                    <div className="container">
                        <div className="footer-top fl-wrap">
                            <a href="index.html" className="footer-logo"><img src="/assets/images/logo2.png" alt=""/></a>
                        
                            <div className="footer-social">
                                <span className="footer-social-title">Follow us :</span>
                                <ul>
                                    <li><a href="https://www.facebook.com/ZaitoonTR/" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                                    <li><a href="https://twitter.com/ZaitoonTR"  target="_blank"><i className="fab fa-twitter"></i></a></li>
                                    <li><a href="https://www.instagram.com/zaitoontr/"target="_blank"><i className="fab fa-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="footer-widget-wrap fl-wrap">
                            <div className="row">
                          
                                <div className="col-md-5">
                                    <div className="footer-widget pr-3">
                                        <div className="footer-widget-title">About us</div>
                                        <div className="footer-widget-content">
                                            <p>Zaitoon the Restaurant is a multi cuisine Veg/Non Veg restaurant started in August 2021 in the premises of ANG Farms and Resorts in Vinegaon, on old Mumbai pune Highway, with a seating capacity of close to 200 with both Indoor and Outdoor seating.</p>
                                             
                                        {/*<Link to="/about" className="active">Read more</Link>*/}                                                 	
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="footer-widget">
                                        <div className="footer-widget-title">Quick Menu</div>
                                        <div className="footer-widget-content">
                                            <div className="footer-contacts footer-box fl-wrap">
                                                <ul>
                                           <li><Link to="/about" className="active">About</Link></li>
                                           <li><Link to="/gallery" className="active">Gallery/Video</Link></li>
                                           <li><Link to="/contact" className="active">Contact</Link></li>
                                                </ul>
                                            </div>
                                                                                  	
                                        </div>
                                    </div>
                                </div>  
                             
                                <div className="col-md-4">
                                    <div className="footer-widget">
                                        <div className="footer-widget-title">Contact info  </div>
                                        <div className="footer-widget-content">
                                            <div className="footer-contacts footer-box fl-wrap">
                                                <ul>                                                   
                                                    <li><span>Khalapur:</span>+91 77382 17738</li>
                                                    <li><span>Write  :</span> zaitoontr@gmail.com</li>
                                                    <li><span>Khalapur Address:</span>ANG Farms & Resorts, Old Mum-Pune Hwy, Vinegaon, MH-410202</li>
                                                   
                                                </ul>
                                            </div>
                                                                                              	
                                        </div>
                                    </div>
                                </div>                                                         
                               
                            </div>
                        </div>
                       
                        <div className="footer-bottom fl-wrap">
                            <div className="copyright">&#169; Zaitoon 2022 . All rights reserved. Powerd By <a className='powerd_by' target="_blank" href="http://vstacks.in/">vStacks</a></div>
                           {/* <div className="to-top"><span>Back To Top </span><i className="fal fa-angle-double-up"></i></div>*/}
                        </div>
                    </div>
                </footer>

                  <div className="reservation-modal-wrap">
                    <div className="reserv-overlay crm">
                      <div className="cd-reserv-overlay-layer" data-frame="25">
                      <div className="reserv-overlay-layer"></div>
                      </div>
                    </div>
                    <div className="reservation-modal-container bot-element">
                      <div className="reservation-modal-item fl-wrap">
                      <div className="close-reservation-modal crm"><i className="fal fa-times"></i></div>
                      <div className="reservation-bg"></div>
                      <div className="section-title">
                      <h4>Hungry?</h4>
                      <h2>Khalapur And Nerul near you.</h2>
                      <div className="dots-separator fl-wrap"><span></span></div>
                      </div>
                      <div className="reservation-wrap">
                      <div id="reserv-message"></div>
                      <div className="search-container">
                      <form action="/action_page.php">
                      <input type="text" placeholder="Enter your delivery location" name="search"/>
                      <button type="submit">FIND FOOD</button>
                      </form>
                      </div>
                      </div>
                      </div>
                    </div>
                  </div>
                </>
  );
    }
};

const mapStateToProps = state =>{
    return{
        status:state._loaderReducer.status
    }
}
export default connect(mapStateToProps,null)(Footer)

