//import {redux } from 'redux';
import {SHOW_LOADER,HIDE_LOADER} from  '../actions/loaderAction';

var initLoader={status:false};


function loaderReducer(state = initLoader,action){
    switch(action.type){
        case SHOW_LOADER:
                state.status=true; 
                return{
                    ...state
                }
        case HIDE_LOADER:
            state.status=false; 
            return{
                ...state
            }
                           
        default:
            return state;
    }
}
  

  export default loaderReducer;
  