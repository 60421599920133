import PropTypes from 'prop-types';
import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const PageSpinner = ({ color = 'primary' }) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;
  return (
    <div className="cr-page-spinner">
    <div className="loader-container" ><Spin indicator={antIcon} /></div> 
    </div>
  );
};

PageSpinner.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'light',
    'dark',
  ]),
};

export default PageSpinner;
