/* eslint-disable no-sequences */
import axios from 'axios';
import { notification } from 'antd';

export default class Request {

  static apiUrl = process.env.REACT_APP_API_URL;
  static axiosConfigInit() {
    axios.defaults.baseURL = this.apiUrl;
    console.log( process.env.REACT_APP_API_URL);
  }

  static setformData(params){
      if(typeof params === 'object' && !Array.isArray(params) && params !== null){
        const formData = new FormData()
        Object.keys(params).forEach((key) => {
        formData.append(key, params[key])
        })
        return formData;
      }else{
        return params;
      }
    
}




  static async axios(method = 'get', action, params="") {

    if(method=="post"){
      params.action= action;
    }
     
    let handleMethod = method === 'get' && params ? { params } : this.setformData(params);
    const user_info = JSON.parse(localStorage.getItem('userInfo'));
    let token = '';
    if (user_info) {
      token = user_info.token;
    }
    if (token && token.length > 0 && action!='login' && action!='register') {
      axios.defaults.headers.Authorization = `${token}`;
    }
    return new Promise((resolve, reject) => {
     axios[method](this.apiUrl, handleMethod)
        .then(res => {
          let response ='' ; //JSON.parse(res.data);
          if((typeof res.data === 'object') || (typeof res.result === 'object'))
          {
            response = res.data ? res.data:res.result;
          }else{
            response=res;
          }
          
          resolve(response);
        }).catch(error => {
          notification.error({
            message: `Internal server ${error}`,
          });
          if(error.response.status === 401) {
            localStorage.removeItem('userInfo');
            notification.error('Token expired or unauthorized.');
            window.location.href = '/';
          }else if(String(error)=="Error: Network Error"){
        }else {
            reject(error.response ? error.response.data : error);
          }
        });
    });
  }
}
