//import {redux } from 'redux';
import {EMPTY_CART,GET_ALL_PRODUCT,GET_NUMBER_CART,ADD_CART, DECREASE_QUANTITY, INCREASE_QUANTITY, DELETE_CART,ADD_INITIAL_CART} from  '../actions/cartAction';

var initProduct="";
const storecart  = loadFromLocalStorage('cartInfo');
if(storecart!="" && storecart!=undefined){
     initProduct = storecart;
}else{
    initProduct = {
        numberCart:0,
        Carts:[]
    }
}

function cartReducer(state = initProduct,action){
    switch(action.type){
        case GET_ALL_PRODUCT: 
          action.payload['Carts'].map((item,key)=>{
                        let _cart = {
                            id:item.item_id,
                            quantity:item.qty,
                            name:item.item_name,
                            image:item.itemImage,
                            price:item.price
                        }
                        state.Carts.push(_cart);
                    });
                    
                    state.numberCart=action.payload['numberCart'];  
                    saveToLocalStorage(state); 
            return{
                ...state
            }
        case GET_NUMBER_CART:
                return{
                    ...state
                }
        case ADD_CART:

            const storelocation  = loadFromLocalStorage('locationInfo');
                if(storelocation===undefined){
                     return false;
                }

            if(state.numberCart==0){
                let cart = {
                    id:action.payload.itemId,
                    quantity:1,
                    name:action.payload.item_name,
                    image:action.payload.itemImage,
                    price:action.payload.price,
                    location_id:storelocation.id
                } 
                state.Carts.push(cart); 
                
            }
            else{
                let check = false;
                state.Carts.map((item,key)=>{
                    if(item.id==action.payload.itemId){
                        state.Carts[key].quantity++;
                        check=true;
                    }
                });
                if(!check){
                    let _cart = {
                        id:action.payload.itemId,
                        quantity:1,
                        name:action.payload.item_name,
                        image:action.payload.itemImage,
                        price:action.payload.price,
                        location_id:storelocation.id
                    }
                    state.Carts.push(_cart);
                }
                
            }
         
            let retrundata  = {
                ...state,
                numberCart:state.numberCart+1
            }
            saveToLocalStorage(retrundata);
            return retrundata;
            case INCREASE_QUANTITY:
              
                state.numberCart++
                state.Carts[action.payload].quantity++;
                saveToLocalStorage(state);
               return{
                   ...state
               }
            case DECREASE_QUANTITY:
                let quantity = state.Carts[action.payload].quantity;
                if(quantity>1){
                    state.numberCart--;
                    state.Carts[action.payload].quantity--;
                }
                saveToLocalStorage(state);
                return{
                    ...state
                }
            case DELETE_CART:
                let quantity_ = state.Carts[action.payload].quantity;
               let data=  {
                    ...state,
                    numberCart:state.numberCart - quantity_,
                    Carts:state.Carts.filter(item=>{
                        return item.id!=state.Carts[action.payload].id
                    })
                }
                saveToLocalStorage(data);
                return data;
            case EMPTY_CART:
                //console.log("emptyemptyemptyemptyempty");
                let dataCart=   {
                    numberCart:0,
                    Carts:[]
                }
                saveToLocalStorage(dataCart);
                return dataCart;
           
                case ADD_INITIAL_CART:  
                    state = {
                        numberCart:0,
                        Carts:[]
                    }
                return  {
                    ...state
                }
               
        default:
            saveToLocalStorage(state);
            return state;
    }
}
// convert object to string and store in localStorage
function saveToLocalStorage(state) {
    try {
      const serialisedState = JSON.stringify(state);
      localStorage.setItem("cartInfo", serialisedState);
    } catch (e) {
      console.warn(e);
    }
  }
  
  // load string from localStarage and convert back in to an Object
  // invalid output must be undefined
  function loadFromLocalStorage(Info) {
    try {
      const serialisedState = localStorage.getItem(Info);
      if (serialisedState === null) return undefined;
      return JSON.parse(serialisedState);
    } catch (e) {
      console.warn(e);
      return undefined;
    }
  }
  
  

  export default cartReducer;
  