import React from 'react';
import { Modal, Button, Form, Input, notification,Checkbox,Card,message, Row, Col } from 'antd';
import Request from '../../utils/request';
import { UserOutlined,LockOutlined } from '@ant-design/icons';
import { getUserLS } from '../../utils/user';
import  {connect} from  'react-redux'
import {EmptyCart,AddInitialCart,GetAllProduct} from '../../redux/actions/cartAction';
import {Link} from 'react-router-dom';
import { loadFromLocalStorage } from '../../utils/common';
import {showLogin} from '../../redux/actions/loginAction';
import {setuserProfile} from '../../redux/actions/userAction';
import CurrentLocation from 'react-current-location-address';
import { withRouter } from "react-router";


const layout = {
  labelCol: { span:6 },
  wrapperCol: { span: 14 },
};
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const verificationLayout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};


const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not validate email!',
    number: '${label} is not a validate number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
    len: '${label} must be ${len} numbers',
  },
  string: {
    len: '${label} must be ${len} numbers',
  },
};


class Login extends React.Component {

  constructor(props) {
    super(props);
   
    this.state = {
    visible: false,
    username: '',
    password: '',
    phone:'',   
    notlogin:true,
    isRegister:false,
    registerValues: {},
    expireIn: 0,
    loadingSubmit: false,
    step: 'sign-up',
    verificationCode: '',
    password: '',
    confirmPassword: '',
    verifiedCaptcha:false,
    verifyLoading:false,
    showSpinner:true,
    isForgotPassword:false,
    isLogin:true,
    virtualUserId:''
    
  }
}


   componentDidMount() {
  
    this.setState({ showSpinner: false });
    if(navigator.geolocation)
    {

    }
  
    const user_info = getUserLS();
    if(user_info){
      this.setState({notlogin:false});
    } 
   // console.log("11111111111111111");
    console.log(this.props.getLoginPopupStatus.isLogin);
  }


  showModal = () => {
    this.props.showLogin({isLogin:true});
    this.setState({
     // visible: true,
      isRegister:false,
      isForgotPassword: false,
    });
  };

 // this.props.islogin



  handleOk = () => {
    this.props.showLogin({isLogin:false});
    this.setState({
    //  visible: false,
      isForgotPassword: false,
    });
  };

  handleCancel = () => {

    this.props.showLogin({isLogin:false});
    this.setState({
     // visible: false,
      isForgotPassword: false,
    });
  };

  forgotPassword = () =>{
    this.setState({
      isForgotPassword: true,
    });

  }
  isLogin =()=>{
    this.setState({
      isForgotPassword: false,
      isLogin:true
    });

  }


  
  _onChange = e => {
    const {value } = e.target;
    console.log(value);
    this.setState({ stopPurchasing: e });
  };
//   _handleInputChange = e => {
//  //   console.log(`[${e.target.name}]: ${e.target.value}`);
//     this.setState({
//       [e.target.name]: e.target.value,
//     });
//   };

 onFinish = async (values) => {
const {isForgotPassword,username,password}  = this.state;
console.log(`form submit called ${username} and values is ${values}`);
  if(isForgotPassword)
  {
    if(values.username=="")
    {
      notification.error({
        message: 'Error',
        description:
          'Please enter your email', 
      }, 'error');
     return;
    }
    try{
      this.setState({ loadingSubmit: true });
      const inputData = {email:values.username}
      const res = await Request.axios('post', 'forgotpassword', values);
      if (res && res.result.status==1) {
        this.setState({ loadingSubmit: false });
        message.success(res.result.message);
        return;
      }else{
        this.setState({ loadingSubmit: false });
        message.error(res.result.message);
        return;
      }
    }catch(error){
      message.error(error);
      return;
    }

  }else {
    if((values.username=="")|| (values.password==""))
   {
    notification.error({
      message: 'Error',
      description:
        'Please Enter Username/Password', 
    }, 'error');
   return;
  }

   try {
    
    // get local storage data for cart
    const cartInfo = localStorage.getItem("cartInfo");

    if (cartInfo === null){ values.cartInfo=[]}else{
        const cartInfo = loadFromLocalStorage('cartInfo');
        values.cartInfo= JSON.stringify(cartInfo.Carts);
    }
    const res = await Request.axios('post', 'login', values);
    if (res && res.result.status==1) {
      localStorage.setItem('userInfo', JSON.stringify(res.result));
      this.setState({notlogin:false});
      const userInfo = {
        firstName: res.result.data.fname,
        lastName: res.result.data.lname,
        mobile: res.result.data.phone,
        email: res.result.data.user_name
      };
      this.props.setuserInfo(userInfo);
      // Popup
      Modal.success({
        title: 'You have successfully logged in',
        onOk: this.confirm,
      });
      this.handleCancel();

  //  get cart data 
     localStorage.removeItem('cartInfo');
     this.getCart();




    } else {
      message.error(res.result.message);
    }
  } catch (error) {
   

    message.error(error);
  }
   }
   
  

};
getCart = async ()=>{
  try {
    const res = await Request.axios('post', 'getCart', {});
    if (res && res.result.status==1) {
    let initProduct = {
        numberCart:res.result.itemtotal,
        Carts:res.result.data
    }
      this.props.AddInitialCart();
      this.props.GetAllProduct(initProduct);
    } else {
     // message.error(res.result.message);
    }
  } catch (error) {
    //message.error(error);
  }

}
 logout = () => {
 
  //window.socket.disconnect();
  localStorage.removeItem('userInfo');
  this.setState({notlogin:true});
  localStorage.removeItem('cartInfo');
  localStorage.removeItem('userProfile');
  this.props.EmptyCart();
 // window.location.href = '/';
  //initApp(false);
   //  this.props.history.replace("/");
   //this.props.history.push('/');
   this.props.history.push('/');
  //this.context.router.history.push('/')
};

showRegisterModal = () =>{
  this.props.showLogin({isLogin:true});
  this.setState({isRegister:true});

}

loginmodel= () =>{
  this.props.showLogin({isLogin:true});
  this.setState({isRegister:false});

}

 showLogoutModal = () => {
  Modal.confirm({
    onOk: this.logout,
    title: 'Are you sure to logout?',
  });
};




handleChange = event => {
  const { target } = event;
  this.setState({ [target.name]: target.value });
};

onChange= (e)=> {
  console.log(`checked = ${e.target.checked}`);
}

handleClick = () => {
  this.props.setValue(this.state);
};

/*
sendEmailConfirmRequest = async values => {
  // console.log('\n---   sendEmailConfirmRequest   ---\n', this);
 
  const { password, confirmPassword } = values;
  const {email } = values;
   if (password !== confirmPassword) {
       message.error('Passwords and confirm password not matached');
    return;
  } 
  

  try {
    const res = await Request.axios('post', '/api/v1/email/confirm', { email });

    if (res && res.result.status) {
      this.setState({
        expireIn: res.expireIn,
        step: 'verification',
      });
      message.success(res.result.message);
    } else {
      message.error(res.result.message);
    }
  } catch (error) {
    message.error('Failed to sign up');
  }
};
*/

// 
recaptchaloaded=()=>{
  console.log("Captcha loaded.......");
  this.setState({ verifiedCaptcha: true });
}


onGoBack = () => {
  this.setState({ step: 'sign-up', verificationCode: '' });
};

verifyPhone = async values => {
  const { registerValues,virtualUserId } = this.state;
  const { verificationCode } = values;

 this.setState({ loadingSubmit: true });
 registerValues.otp=verificationCode;
 registerValues.userId=virtualUserId;

  try {
    const res = await Request.axios('post', 'register', registerValues);
    if (res && res.result.status==1) {
      //localStorage.setItem('userInfo', JSON.stringify(res.result));
      //this.setState({notlogin:false});
      // Popup
      message.success('You have successfully register' );
      this.handleCancel();
      this.setState({ step: 'sign-up',verificationCode: '' , isRegister:false });

    } else {
      message.error(res.result.message );
      this.setState({ loadingSubmit: false });
    }
  } catch (error) {
    message.error(error);
    this.setState({ loadingSubmit: false });
  
  }
  this.setState({ loadingSubmit: false });

};
onChangecheck(e) {
  console.log(`checked = ${e.target.checked}`);
}    


onRegsiterFinish = async values => {
  //localStorage.removeItem('userInfo');
  this.setState({ loadingSubmit: true });
  //this.setState({step:'verification',registerValues:values});
  
  //await this.sendEmailConfirmRequest(values);

  try {
    const res = await Request.axios('post', 'signupconfirm', { email:values.email,phone:values.phone });

    if ((res) && (res.result.status==="1")) {
      this.setState({ loadingSubmit: false });
      this.setState({step:'verification',registerValues:values,virtualUserId:res.result.data.userId});
      //message.success(res.result.message);
    } else {
      message.error(res.result.message);
      this.setState({ loadingSubmit: false });
    }
  } catch (error) {
    this.setState({ loadingSubmit: false });
    message.error('Failed to sign up');
  }


  

  // try {
  //   const res = await Request.axios('post', 'register', values);
  //   if (res && res.result.status==1) {
  //     //localStorage.setItem('userInfo', JSON.stringify(res.result));
  //     //this.setState({notlogin:false});
  //     // Popup
  //     message.success('You have successfully register' );
  //     this.handleCancel();

  //   } else {
  //     message.error(res.result.message );
  //   }
  // } catch (error) {
  //   message.error(error);
  
  // }
  // this.setState({ loadingSubmit: false });
};
  render() {
    const {notlogin,isRegister,loadingSubmit, step, expireIn ,verifyLoading,isForgotPassword} = this.state;
    return (
      <>
      {notlogin &&
        <a onClick={() => this.showModal()}>
          Log in 
        </a>
        }
      
         {!notlogin &&
        <>
          <a href="#">My Account<i className="fas fa-caret-down"></i></a>
          <ul>
            
          <li key="1"><Link to="/profile" className="active">Profile</Link > </li>
          <li key="2"><Link to="/manageaddress" className="active">Manage Address</Link > </li>
          <li key="3"> <a  onClick={this.showLogoutModal}>Logout</a> </li>
          </ul>
        </>
          
          }

{notlogin && !isRegister  &&
        <Modal
          title="Log in"
          visible={this.props.getLoginPopupStatus.isLogin}
          onOk={() => this.handleOk()}
          onCancel={this.handleCancel}
          width={480}
          footer={null}
        >
       <Form
      name="normal_login"
      className="login-form"
      initialValues={{
        remember: true,
      }}
      onFinish={this.onFinish}
    >
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: 'Please input your Email!',
          },
        ]}
      >
        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
      </Form.Item>
      {!isForgotPassword && (
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input your Password!',
          },
        ]}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Password"
        />
      </Form.Item>
      )}
      <div className='remeber_forgot'>
       {!isForgotPassword && (
        <Form.Item name="remember" valuePropName="checked" noStyle>
          <Checkbox>Remember me</Checkbox>
        </Form.Item>   
       )}
          {!isForgotPassword &&( <a className="login-form-forgot" href="#" onClick={this.forgotPassword}>
          Forgot password
        </a>)}
        </div>
      <Form.Item>
        <Button type="primary" htmlType="submit" className="login-form-button" loading={loadingSubmit}>
        {!isForgotPassword ? "Log in":"Submit"}
        </Button>
          or  {!isForgotPassword &&(<a className='register_now' onClick={this.showRegisterModal}>Register</a>
          )}
          {isForgotPassword &&(<a onClick={this.isLogin}>Signin</a>)}
        {/* <SignUp></SignUp> */}
      </Form.Item>
     
   
      
    </Form>
    
        </Modal>
      }

{isRegister  &&

<Modal
title="Sign Up"
visible={this.props.getLoginPopupStatus.isLogin}
onOk={() => this.handleOk()}
onCancel={this.handleCancel}
width={600}
footer={null}
>
{/* <Card
title="Sign Up"
bordered={false}
style={step === 'sign-up' ? {} : { display: 'none' }}
> */}

{step != 'verification' && (
<Form
  {...layout}
  name="sign-up"
  onFinish={this.onRegsiterFinish}
  validateMessages={validateMessages}
>
  <Form.Item name="fname" label="First Name" rules={[{ required: true }]}>
    <Input placeholder="Input your first name" prefix={<UserOutlined />}/>
  </Form.Item>
  <Form.Item name="lname" label="Last Name" rules={[{ required: true }]}>
    <Input placeholder="Input your last name" prefix={<UserOutlined />}/>
  </Form.Item>
  
  <Form.Item name="email" label="Email" rules={[{ type: 'email', required: true }]}>
    <Input placeholder="Input your email" />
  </Form.Item>
  <Form.Item name="phone" placeholder="Input your mobile" label="Mobile"  rules={[{
                        required: true,message: "A value must be entered",
                        pattern: new RegExp(/^[0-9]+$/)}]}  >
                      <Input name="phone" minLength={10} maxLength={10}/>
  </Form.Item>

 
  <Form.Item name="password" label="Password" rules={[{ required: true,pattern: new RegExp("^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,20}$") ,message:"Wrong format"},]} help="Minimum 8 characters with a capital letter a number a small letter and a special character.">
    <Input.Password placeholder="Input password" />
   
  </Form.Item>

  <Form.Item name="confirmPassword" label="Confirm Password" dependencies={['password']} hasFeedback 
  rules={[{ required: true },({ getFieldValue }) => ({
  validator(_, value) {
    if (!value || getFieldValue('password') === value) {
      return Promise.resolve();
    }
    return Promise.reject('The two passwords that you entered do not match!');
  },
}),]}>
    <Input.Password placeholder="Input confirm password" prefix={<LockOutlined />}/>
  </Form.Item>
  
  <Form.Item {...tailFormItemLayout} name="TermsandConditions"  valuePropName="checked"  rules={[
    {
      validator: (_, value) =>
        value ? Promise.resolve() : Promise.reject('Should accept terms and conditions!'),
    },
   ]}>
    <Checkbox className="agree-box">I agree to the <a href="/tos" target="_blank">Terms of services</a> and <a href="/pp" target="_blank">Privacy Policy</a></Checkbox> 
    </Form.Item>
  
  {/* <Form.Item {...tailFormItemLayout}>  
  <ReCAPTCHA sitekey={googleSitekey} onChange={this.recaptchaloaded} onExpired={this.onExpiredCaptcha}/>
  </Form.Item> */}
  <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }} className="no-border">   
    <Button type="primary" htmlType="submit" loading={loadingSubmit}>
      Submit
    </Button>
   
  </Form.Item>

  <Form.Item wrapperCol={{ ...layout.wrapperCol, offset:1 }} className="no-border text-left">   
  <Link to="" onClick={()=>this.loginmodel()}>Back To Login</Link>
   
  </Form.Item>

</Form>
)}

{step === 'verification' && (
<Card title="Verify your phone" bordered={false}>
  <Form
    {...verificationLayout}
    name="verification"
    onFinish={this.verifyPhone}
    validateMessages={validateMessages}
    className="sign-up-verification-form"
  >
    <p>
      We sent verification code to your phone. Please input the code below.
    </p>

    <Form.Item name="verificationCode" label="Code" rules={[{ required: true, len: 6 }]}>
      <Input placeholder="Verification code" prefix={<LockOutlined />}/>
    </Form.Item>

    <Form.Item className="no-border">
      <Row justify="space-between" >
        <Col span={12} className="btn-box">
          <Button
            onClick={this.onGoBack}
            style={{ width: '100%' }}
            disabled={verifyLoading}
          >
            Go Back
          </Button>
        </Col>

        <Col span={12}>
          <Button
            htmlType="submit"
            loading={verifyLoading}
            style={{ width: '100%' }}
          >
            Verify
          </Button>
        </Col>
      </Row>
    </Form.Item>
  </Form>
</Card>
)}
{/* 
<CurrentLocation
  onFetchAddress={(results) => {}}
  onError={(type, status) => {}}
 >
  {({ getCurrentLocation, loading }) => (
    <button onClick={getCurrentLocation}>
      Get Current Location
    </button>
  )}
</CurrentLocation>
*/}
</Modal>

  }
      </>
    );
  }
}


const mapStateToProps = state =>{
  return{
    items:state._cartReducer,
    getLoginPopupStatus:state._loginReducer,
    userProfile:state._userReducer
  }
}

const mapDispatchToProps = dispatch => ({
  EmptyCart() {
    dispatch(EmptyCart());
  },
  AddInitialCart() {
    dispatch(AddInitialCart());
  },
  GetAllProduct(dataCart){
    dispatch(GetAllProduct(dataCart));
  },
  showLogin(islogin){
    dispatch(showLogin(islogin));
  },
  setuserInfo(data){
    dispatch(setuserProfile(data));
  }
 
  
});
const SigninWrapper = withRouter(Login);
export default connect(mapStateToProps,mapDispatchToProps)(SigninWrapper)
