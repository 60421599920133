import {SHOW_LOGIN,LOGIN_POPUP} from '../actions/loginAction';

let initLogin = {isLogin:false};

function loginReducer(state=initLogin,action){
    switch(action.type){
        case SHOW_LOGIN:          
        state.isLogin=action.payload.isLogin;
      //  console.log(state);
        return{
            ...state
        }
        case LOGIN_POPUP:
            return{
                ...state
            }
        default:
            return state
    }

}



export default loginReducer;