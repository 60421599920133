import { Content,Footer,Header} from '../Layout'
import React from 'react';
// import {
//   MdImportantDevices,
//   // MdCardGiftcard,
//   MdLoyalty,
// } from 'react-icons/md';
//import NotificationSystem from 'react-notification-system';
//import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';

class MainLayout extends React.Component {

  render() {
    const { children } = this.props;
    return (
      <div id="main" style={{opacity: 1}}>
      <div id="wrapper">
         {/* <Sidebar /> */}
        
           <Header />
           {children}
         <Footer/>
        

        {/* <NotificationSystem
          dismissible={false}
          ref={notificationSystem =>
            (this.notificationSystem = notificationSystem)
          }
          style={NOTIFICATION_SYSTEM_STYLE}
        /> */}
      </div>
      </div>
    );
  }
}

export default MainLayout;
