import logo from './logo.svg';
import './App.css';
import React from 'react';

import { BrowserRouter as Router , Redirect, Route, Switch,useLocation } from 'react-router-dom';
import PageSpinner from './components/PageSpinner';
import GuardedRoute from './components/GuardedRoute';
import {  MainLayout,LayoutRoute,EmptyLayout } from './components/Layout';
import {getUserLS} from './utils/user';
import OurMenu from './pages/MenuPage';

const HomePage = React.lazy(() => import('./pages/HomePage'));
const BrowserLocation = React.lazy(()=>import('./components/BrowserLocation'));
const LocationPage = React.lazy(()=>import('./pages/LocationPage'));
const Profile = React.lazy(()=>import('./pages/Profile/Profile'));
const cartPage = React.lazy(()=>import('./pages/cartPage'));
const Gallery = React.lazy(()=>import('./pages/Gallery'));
const ContactUS = React.lazy(()=>import('./pages/ContactUS'));
const AboutUs = React.lazy(()=>import('./pages/AboutUs'));
const MenuPage = React.lazy(()=>import('./pages/MenuPage'));
const checkoutPage = React.lazy(()=>import('./pages/checkoutPage'));
const ManageAddress = React.lazy(()=>import('./pages/ManageAddress/ManageAddress'));
const ResetPasswordPage = React.lazy(()=>import('./pages/ResetPasswordPage'));
const ChangePassword = React.lazy(()=>import('./pages/ChangePassword'));
const PageNotFound = React.lazy(()=>import('./pages/PageNotFound/PageNotFound'));
const MyOrder = React.lazy(()=>import('./pages/MyOrder/MyOrder'));
const Deliveryorder = React.lazy(()=>import('./pages/DeliveryOrder/DeliveryOrder'));
const Event = React.lazy(()=>import('./components/Event/Event'));

const user_info = getUserLS();


function App() {
  const [isAutheticated,setAuth]= React.useState(false);

React.useEffect(()=>{
  if(user_info!== undefined)
  {
    //console.log('authenticated....');
    setAuth(true);
    
  }
},[]);
  
  return (
    <div className="App">
      <Router>
      <React.Suspense fallback={<PageSpinner />}>
          <Switch>
            <LayoutRoute exact path="/login" layout={EmptyLayout} component={NoMatch} />
            <MainLayout>
              <Route exact path="/" component={HomePage} />
              <Route path="/location/:locationId" component={LocationPage} />
              <Route path="/cart" component={cartPage} />
              <Route path="/AboutCounter" component={AboutUs}/>
              <Route path="/gallery" component={Gallery} />
              <Route path="/about" component={AboutUs} />
              <Route path="/menu" component={MenuPage} />
              <Route path="/contact" component={ContactUS} />   
              <Route path="/eventbooking" component={Event} />        
              <GuardedRoute path="/checkout" component={checkoutPage} auth={isAutheticated} />            
              <GuardedRoute path="/profile" component={Profile} auth={isAutheticated} />
              <GuardedRoute path="/manageaddress" component={ManageAddress} auth={isAutheticated} />
              <GuardedRoute path="/changepassword" component={ChangePassword} auth={isAutheticated} />
              <GuardedRoute path="/myorder" component={MyOrder} auth={isAutheticated} />
              <GuardedRoute path="/deliveryorder" component={Deliveryorder} auth={isAutheticated} />            
              <Route path="/resetpassword/:code" component={ResetPasswordPage} />   
              <Route path="/getlocation" component={BrowserLocation}   />
             
            <Route path="/404" component={PageNotFound} />     
             
              
            </MainLayout> 
           
            
          </Switch>     
             
          </React.Suspense>
      </Router>
    </div>
  );
}

function NoMatch() {
  let location = useLocation();

  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
}
export default App;